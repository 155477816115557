* {
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #fafbfc;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #292929;
}

a:hover {
    text-decoration: underline;
}

.content-wrapper {
    max-width: 1024px;
    min-width: 240px;
    margin: 0 auto;
}

.anchor {
    display: block;
    height: 120px;
    margin-top: -120px;
    visibility: hidden;
}

footer {
    margin: 0;
    padding: 1px 0 10px 0;
    color: #727171;
    text-align: center;
    font-size: 13px;
}

header {
    height: 700px;
    margin: 0;
    padding: 45px 100px;
    background: url(data/header_bg.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

header .image-text {
    color: #f2f2f2;
    text-shadow: #000 2px 2px 0;
    height: 150%;
    vertical-align: middle;
    margin-top: 150px;
}

.image-text h1 {
    font-size: 90px;
    margin: 0;
}

.image-text .subtitle {
    font-size: 35px;
}

address {
    font-style: normal;
}

.mobile-nav {
    display: none;
}

.navigation-wrapper {
    margin: 0 auto;
    height: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    transition: all 0.4s;
    z-index: 1;
}

.navigation-wrapper img {
    height: 100%;
    width: auto;
    padding: 10px;
}

.navigation-wrapper>a {
    height: inherit;
}

.navigation-wrapper nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: baseline;
}

.navigation-wrapper nav ul li {
    margin: 0 10px;
    padding: 0 5px;
    width: auto;
    text-align: center;
    justify-content: space-around;
    font-size: 26px;
}

.navigation-wrapper nav ul li a {
    text-decoration: none;
    color: #292929;
    width: 100%;
    height: 100%;
    display: block;
}

.navigation-wrapper nav ul li a:hover,
.navigation-wrapper nav ul li a.active {
    text-decoration: underline;
    color: #7e1a13;
    transition: all 0.3s;
}

header .info {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}

.page-contact .info {
    margin-top: 25px;
}

.contact {
    display: flex;
    flex-direction: column;
    margin-right: 55px;
}

.contact a {
    text-decoration: none;
    color: black;
}

.contact a:hover {
    text-decoration: underline;
}

.opening-times {
    display: flex;
    flex-direction: column;
}

.page-contact .opening-times {
    margin: 25px 0 0 0;
}

.opening-times .row .day {
    min-width: 55px;
    display: inline-block;
}

.page-contact .row .day {
    min-width: 75px;
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background-color: #fafbfc;
    box-shadow: 0 1px 10px 3px rgba(0, 0, 0, 0.15);
    padding-right: 20px;
}

.sticky+.content-wrapper {
    padding-top: 120px;
}

.sticky nav ul li {
    height: 90px;
    line-height: 90px;
}

h2 {
    font-size: 45px;
    padding: 20px 0;
    margin: 0;
}

.page {
    padding: 30px 0;
}

.page-map {
    padding-bottom: 0;
}

.page-sales-form,
.page-contact,
footer {
    background-color: #eef3f7;
}

.page-cars {
    background-color: #fff;
}

.form-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 35px 0;
}

.form-fieldset {
    width: 50%;
}

.form-fieldset legend {
    font-style: italic;
}

.form-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-item {
    display: flex;
    flex-direction: column;
    margin: 15px 20px 15px 0;
    align-items: baseline;
    width: 40%;
}

.form-item label {
    font-weight: bold;
}

.form-item input,
.form-item textarea {
    padding: 8px;
    border-radius: 2px;
    border: none;
    background-color: #fafbfc;
    margin-top: 5px;
    font-size: 18px;
    width: 100%;
}

.form-item-textarea {
    flex-grow: 2;
    width: 60%;
}

.form-item-textarea textarea {
    height: 100px;
    width: 85%;
}

.gotcha {
    display: none;
}

.submit {
    color: #7e1a13;
    background-color: transparent;
    border: 1px solid #7e1a13;
    border-radius: 2px;
    margin: 0;
    padding: 13px 28px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s;
}

.submit:hover {
    color: #c6453b;
    border-color: #c6453b;
}

.form-item ::placeholder {
    color: #a6a6a6;
}

.contact-wrapper {
    display: flex;
    flex-direction: row;
}

.contact-left {
    width: 30%;
}

.page.page-map {
    margin: 0;
    padding: 0;
    width: 70%;
}

#map {
    width: 100%;
    height: 400px;
    background-color: rgba(255, 237, 214, 0.12);
}

@media only screen and (max-width: 1427px) {
    header {
        padding: 10px 20px 10px 0;
    }
}

@media only screen and (max-width: 1243px) {
    header .navigation-wrapper nav ul li {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1094px) {
    header {
        padding: 12px;
    }
    .navigation-wrapper .info {
        display: none;
    }
    .sticky {
        padding: 0;
    }
    .sticky .info {
        display: block;
        font-size: 8px;
    }
}

@media only screen and (min-width: 770px) and (max-width: 992px) {
    .content-wrapper {
        padding: 0 15px;
    }
    .sticky {
        height: 75px;
    }
    header {
        height: 400px;
    }
    .navigation-wrapper nav ul li {
        height: 75px;
        line-height: 75px;
        font-size: 19px;
        margin: 0 5px;
        padding: 0 8px;
    }
    header .image-text {
        margin-top: 40px;
    }
    header .image-text h1 {
        font-size: 60px;
        line-height: 60px;
    }
}

@media only screen and (max-width: 769px) {
    .content-wrapper {
        padding: 0 15px;
        z-index: 100;
    }
    .navigation-wrapper .info {
        display: none;
    }
    .sticky {
        height: 70px;
    }
    .sticky+.content-wrapper {
        padding-top: 0;
    }
    header {
        height: 300px;
        min-height: 300px;
        padding: 0;
    }
    header .image-text {
        margin: 0;
        padding-top: 140px;
    }
    header .image-text h1 {
        font-size: 50px;
        line-height: 50px;
    }
    .mobile-nav {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 50px;
    }
    .navigation-wrapper nav ul {
        flex-direction: column;
    }
    .navigation-wrapper {
        height: 70px;
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        z-index: 50;
        width: 100%;
        background-color: #fafbfc;
        box-shadow: 0 1px 10px 3px rgba(0, 0, 0, 0.15);
    }
    .navigation-wrapper img {
        display: inline-block;
    }
    #menuToggle {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 25px;
        left: 25px;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;
    }
    #menuToggle input {
        display: flex;
        width: 40px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        margin: -4px 0 0 -4px;
    }
    #menuToggle span {
        display: flex;
        width: 29px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: #303030;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 5px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
    }
    #menuToggle span:first-child {
        transform-origin: 0% 0%;
    }
    #menuToggle span:nth-last-child(2) {
        transform-origin: 0% 100%;
    }
    #menuToggle input:checked~span {
        opacity: 1;
        transform: rotate(45deg) translate(-3px, -1px);
        background: #36383F;
    }
    #menuToggle input:checked~span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    #menuToggle input:checked~span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }
    #menu {
        position: fixed;
        top: 70px;
        left: 0;
        width: 100vw;
        height: 240px;
        box-shadow: 0 10px 15px #e3e3e3;
        margin: 0;
        padding: 20px 30px;
        background-color: #fafbfc;
        -webkit-font-smoothing: antialiased;
        transform-origin: 0% 0%;
        transform: translate(-100vw, 0);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
        list-style-type: none;
        z-index: 10;
    }
    #menu li {
        padding: 10px 0;
        transition-delay: 2s;
        font-size: 24px;
    }
    #menuToggle input:checked~ul {
        transform: translate(0, 0);
    }
    .navigation-wrapper>nav {
        display: none;
    }
    .form-wrapper {
        flex-direction: column;
        margin: 15px 0;
    }
    .form-fieldset {
        width: 100%;
        margin: 10px 0;
    }
    .form-item {
        width: 45%;
    }
    .form-item-textarea textarea {
        width: 92%;
    }
    .contact-wrapper {
        flex-direction: column;
    }
    .contact-left {
        width: 100%;
        margin-bottom: 15px;
    }
    .page.page-map {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    #map {
        width: 100%;
        height: 400px;
    }
    #menuToggle input:checked~nav {
        transform: none;
    }
}

@media only screen and (max-width: 534px) {
    header .image-text {
        padding-top: 140px;
    }
    header .image-text h1 {
        font-size: 40px;
        line-height: 40px;
    }
    header .image-text .subtitle {
        font-size: 24px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 429px) {
    .form-item-textarea textarea,
    .form-item {
        width: 100%;
        margin: 10px 0;
    }
    .submit {
        width: 100%;
    }
}